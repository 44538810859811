import React from "react"
import styled from "styled-components"
import values from "../../utils/values"

import cloudscannLogo from "../../images/gallery/logos/cloudscann/cloudscann-s.png"
import commongroundLogo from "../../images/gallery/logos/commonground/commonground-s.jpg"
import dieSocialistenLogo from "../../images/gallery/logos/dieSocialisten/Die_Socialisten.png"
import frequentisLogo from "../../images/gallery/logos/frequentis/frequentis_logo.png"
import jpmorganLogo from "../../images/gallery/logos/jpmorgan/JP_Morgan-s.png"
import netconomyLogo from "../../images/gallery/logos/netconomy/Netconomy-s.png"
import oegbLogo from "../../images/gallery/logos/oegb/oegb2.png"
import ootbLogo from "../../images/gallery/logos/ootb/ootb-2-s.png"
import preScreenLogo from "../../images/gallery/logos/preScreen/PRE_Screen.png"
import viewpointSystemLogo from "../../images/gallery/logos/viewpointSystem/Viewpointsystem_logo1.png"
import visualc3Logo from "../../images/gallery/logos/visualc3/visualc3-s.jpg"
import wingpaperLogo from "../../images/gallery/logos/wingpaper/wingpaper_logo.png"

const ClientsLogoesWrap = styled.div`
  padding-top: 3rem;
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .img-fixed {
    width: 80px;
    margin-bottom: 5px;

    @media (min-width: ${values.DEVICESIZE.mobileM}) {
      width: 100px;
      margin-bottom: 15px;
    }

    @media (min-width: ${values.DEVICESIZE.mobileL}) {
      margin-bottom: 25px;
    }

    @media (min-width: ${values.DEVICESIZE.tablet}) {
      width: 130px;
    }
    @media (min-width: ${values.DEVICESIZE.laptop}) {
      width: 170px;
      margin-bottom: 35px;
    }

    @media (min-width: ${values.DEVICESIZE.laptopL}) {
      width: 320px;
      margin-bottom: 50px;
    }
    @media (min-width: ${values.DEVICESIZE.desktop}) {
      width: 420px;
      margin-bottom: 90px;
    }
  }
`

export default function ClientsLogoBoard() {
  return (
    <ClientsLogoesWrap>
      <div className="row">
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={frequentisLogo}
              alt="client logo frequentis"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img className="active" src={oegbLogo} alt="client logo oegb" />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img className="active" src={wingpaperLogo} alt="client logo bca" />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={netconomyLogo}
              alt="client logo netconomy"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={cloudscannLogo}
              alt="client logo cloudscann"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={commongroundLogo}
              alt="client logo commonground"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={visualc3Logo}
              alt="client logo visualc3"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img className="active" src={ootbLogo} alt="client logo ootb" />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={dieSocialistenLogo}
              alt="client logo die socialisten"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active logoPG"
              src={jpmorganLogo}
              alt="client logo jpmorgan"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={viewpointSystemLogo}
              alt="client logo viewpoint system"
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-3">
          <div className="img-fixed">
            <img
              className="active"
              src={preScreenLogo}
              alt="client logo prescreen"
            />
          </div>
        </div>
      </div>
    </ClientsLogoesWrap>
  )
}
